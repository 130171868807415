<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col v-if="currentGroupName">
          {{currentGroupName}}
        </i-col>
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsName">货物名称</Option>
                </Select>
              </i-input>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button v-if="$authFunsProxyNoRoute['scm.produce.in_order-get']" style="margin-left: 10px;" @click="goClick" type="primary">入库单编辑器</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:goodsId="{ row }">
          {{ row.goodsName ? row.goodsName + ' ' + row.goodsGgxh : row.goodsGroupName}}
        </template>
        <template v-slot:volume="{ row }">
          {{ row.volume !== null ? (row.volume + ' ' + (row.goodsUnit || '')) : '-' }}
        </template>
        <template v-slot:dealVolume="{ row }">
          {{ row.dealVolume !== null ? (row.dealVolume + ' ' + row.dealVolume) : '-' }}
        </template>
        <template v-slot:status="{ row }">
          <PlanStatus :status="row.status" />
        </template>
        <template v-slot:endTime="{ row }">
          {{row.endTime ? row.endTime.slice(0, 10) : '-'}}
        </template>
      </Table>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import request from '../../api/index'
import PlanStatus from '../../components/plan/status.vue'

export default {
  components: { AppPage,PlanStatus },
  data () {
    return {
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsName',
        value: null
      },
      loading: {
        load: false,
        submit: false,
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      chooseItem: null
    }
  },
  computed: {
    currentGroupId () {
      const currentGroupId = this.$route.query.groupId
      return currentGroupId ? Number(currentGroupId) : null
    },
    currentGroupName () {
      return this.$route.query.groupName
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      return [
        { title: '入库单', key: 'goodsStockInOrderGroupName', fixed: 'left', width: 180 },
        { title: '物品/组', key: 'goodsId', slot: 'goodsId', fixed: 'left', width: 180 },
        { title: '入库仓库', key: 'storehouseName', minWidth: 100 },
        { title: '计划量', key: 'volume', slot: 'volume', minWidth: 100 },
        { title: '完成量', key: 'dealVolume', slot: 'dealVolume', minWidth: 100 },
        { title: '状态', key: 'status', slot: 'status', minWidth: 120  }
      ]
    }
  },
  watch: {
    currentGroupId () {
      this.refresh()
    }
  },
  methods: {
    detail (item) {
      this.chooseItem = item
      this.status.detail = true
    },
    goClick () {
      this.$router.push({name: 'scm.produce.in_order'})
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsName'
      this.query.value = null
      this.query.status = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.currentGroupId) {
        params.goodsStockInOrderGroupId = this.currentGroupId
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_in_order', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))
        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  }
}
</script>
