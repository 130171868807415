<template>
  <Tag class="noborder" :size="size" v-if="data" :color="data.color">{{ data.label }}</Tag>
</template>

<script>
  export default {
    props: {
      status: { type: Number, default: null },
      size: { type: String, default: 'large' }
    },
    computed: {
      data () {
        const status = this.status
        return this.$store.getters.scmPlanStatusList.find(v => v.value === status)
      }
    }
  }
</script>